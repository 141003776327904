
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.wrapper {
  margin-bottom: $space-sm;

  > * {
    display: inline-block;
    margin-right: 0.3rem;
    white-space: nowrap;
  }
}

.price {
  font-size: $font-x-large;
  font-weight: bold;
  margin-top: $space-md;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }
}

.newPrice {
  color: $color-ethiopia-primary;
}

.oldPrice {
  font-size: $font-medium;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    border-top: $border-width solid $color-text-secondary;
    opacity: 0.6;
    transform: rotate(-7deg);
    width: 100%;
    top: 50%;
    left: 0;
  }
}
