
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.variantOptions {
  order: -1;
}

.secondaryText {
  color: $color-text-secondary;
}

.cartButtonRow {
  display: flex;
  gap: $space-sm;
  flex-direction: column;
}

.button {
  transition: background $transition-duration;

  &.colombia {
    background: $color-colombia-primary;
    border: $color-colombia-primary;

    &:hover {
      background: $color-colombia-dark;
      border: $color-colombia-dark;
    }
  }
}
