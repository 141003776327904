
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.alert {
  :global(.mantine-Alert-icon) {
    margin-top: 1px;
  }
}

.actionWrapper {
  padding: $space-xs;
  background-color: $color-warning-medium;
}
